import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Button, Form, Row } from 'react-bootstrap'
import React, { useEffect, useState, useRef } from 'react'
import PageHeader from '@/layouts/components/PageHeader'
import { Link, useHistory } from 'react-router-dom'
import steps from '../Request/process_steps/steps'
import LOCATION from '../../../constants/Location'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import TravelerSelection from '../Request/components/TravelerSelection'
import RoomSelection from "../Request/components/RoomSelection";

function WhoIsGoing ({
  modelEditor,
  handleCancel,
  handleConfirm,
}) {

  const { t } = useTranslation()

  const history = useHistory()

  let step = steps.find(_ => _.name === 'who-is-going')

  let storeValues = localStorage.getItem('trip_values') ?? null

  const [tripValues, setTripValues] = useState(storeValues ? JSON.parse(storeValues) : {})

  let nextStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/what-will-make-it-special`
  let backStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/when`

  const continueActionRef = useRef(null)

  const schema = yup.object().shape({
    rooms: yup.array().of(yup.object().shape({
      adults: yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.adults') })),
      children: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
      children_ages: yup.array()
          .typeError(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
          .when(['children'], function (children, schema) {
            return children && children != 0 ?
                schema.of(yup.number()
                    .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
                    .transform((value) => (isNaN(value) ? undefined : value)))
                : schema.nullable()
          })
    }))
  })

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    unregister,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: tripValues?.who,
    resolver: yupResolver(schema),
  })

  const nextStep = (values) => {
    let who = {
      adults: values.rooms.reduce((acc, room) => acc + room.adults, 0),
      children: values.rooms.reduce((acc, room) => acc + room.children, 0),
      children_ages: values.rooms.reduce((acc, room) => acc.concat(room.children_ages), []).filter(_ => _ !== null),
      rooms: values.rooms,
    }

    localStorage.setItem('trip_values', JSON.stringify({ ...tripValues, ...{ who } }))
    if (!modelEditor) {
      history.push(nextStepLink)
    } else if (handleConfirm) {
      handleConfirm()
    }
  }

  useEffect(() => {
    if (!storeValues) {
      history.push(LOCATION.TRIPS.START_YOUR_JOURNEY.path)
    }
  }, [])

  return (
    <>
      {
        !modelEditor &&
        <>
          <PageHeader
            className="d-none d-sm-flex"
            title={t(step.title)}
            bgColor="primary"
            textColor="dark"
            prefixElements={[
              <Link to={backStepLink} className="btn btn-primary text-uppercase heading-text text-dark fw-semibold">
                <FontAwesomeIcon size="2xl" icon={faArrowLeftLong}/>
                <span className="mx-2">{t('common.buttons.back')}</span>
              </Link>
            ]}
            suffixElements={[
              <Button
                variant="primary"
                className="btn btn-primary text-uppercase heading-text text-dark fw-semibold"
                onClick={() => continueActionRef.current.click()}
              >
                <span className="mx-2">{t('common.buttons.continue')}</span>
                <FontAwesomeIcon size="2xl" icon={faArrowRightLong}/>
              </Button>
            ]}
          />
          <div className="w-100 sm-bg d-block d-sm-none" style={
            {
              backgroundImage: `url("/assets/images/bg/${step.bg_sm}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          }>
          </div>
          <PageHeader
            className="d-block d-sm-none"
            title={t(step.title)}
            bgColor="dark"
            textColor="white"
          />
        </>
      }

      <div className="w-100 h-100 d-flex justify-content-center align-items-sm-start" style={
        !modelEditor ? {
          backgroundImage: `url("/assets/images/bg/${step.bg}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        } : null
      }>
        <div className="mt-sm-5 mt-0 p-sm-5 p-3 trip-process-form">
          <Form noValidate onSubmit={handleSubmit(nextStep)}>
            {/*<TravelerSelection*/}
            {/*  register={register}*/}
            {/*  unregister={unregister}*/}
            {/*  errors={errors}*/}
            {/*  watch={watch}*/}
            {/*/>*/}
            <RoomSelection
              register={register}
              unregister={unregister}
              errors={errors}
              watch={watch}
              setValue={setValue}
              rooms={tripValues?.who?.rooms}
            />
            <Form.Group className="mt-4 d-grid gap-2 modal-buttons">


              {
                modelEditor ?
                  <div className="d-flex justify-content-between w-100">
                    <Button
                      variant="secondary"
                      className="heading-text me-2"
                      onClick={() => handleCancel()}
                    >
                      {t('common.buttons.cancel')}
                    </Button>
                    <Button ref={continueActionRef} className="fw-bold heading-text" type="submit">
                      <span className="text-uppercase">{t('common.buttons.save')}</span>
                    </Button>
                  </div> :
                  <>
                    <Button ref={continueActionRef} className="fw-bold d-block d-sm-none heading-text" type="submit">
                      <span className="text-uppercase">{t('common.buttons.continue')}</span>
                    </Button>

                    <Link to={backStepLink}
                          className="btn btn-light-1 btn-lg fw-bold d-block mt-2 d-sm-none heading-text" type="button">
                      <span className="text-uppercase">{t('common.buttons.back')}</span>
                    </Link>
                  </>
              }
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  )
}

export default WhoIsGoing